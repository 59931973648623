import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { languages, namespaces } from './i18n.constants';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import React from 'react';

export function createI18nProvider() {
  const i18n = i18next.createInstance().use(initReactI18next);
  i18n
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
      react: {
        useSuspense: false,
      },
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      fallbackLng: languages.en_US,
      ns: [namespaces.common],
      interpolation: {
        escapeValue: false,
      },
      supportedLngs: [
        languages.en,
        languages.fr,
        languages.en_US,
        languages.fr_CA,
      ],
    });

  return function I18nClientProvider(props: { children: React.ReactNode }) {
    return <I18nextProvider i18n={i18n}>{props.children}</I18nextProvider>;
  };
}
