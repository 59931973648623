import { useTranslation } from 'react-i18next';

export function useI18n() {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const dir = i18n.dir(locale);
  return { dir, locale, i18n };
}

export function useT(namespace?: string | string[]) {
  const { i18n } = useI18n();
  if (namespace) {
    if (typeof namespace === 'string') {
      if (!i18n.hasLoadedNamespace(namespace)) {
        i18n.loadNamespaces(namespace).catch(() => {
          // Failed to load translation namespace
        });
      }
    } else {
      const filtered = namespace.filter((ns) => !i18n.hasLoadedNamespace(ns));
      if (filtered.length > 0) {
        i18n.loadNamespaces(filtered).catch(() => {
          // Failed to load translation namespace
        });
      }
    }
  }
  return i18n.getFixedT(i18n.language, namespace);
}
